<script>
  export let getSelectionLabel = undefined;
  export let item = undefined;
</script>

<style>.selection {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9zdmVsdGUtc2VsZWN0L3NyYy9TZWxlY3Rpb24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsdUJBQXVCO0VBQ3ZCLGtCQUFrQjtFQUNsQixtQkFBbUI7QUFDckIiLCJmaWxlIjoibm9kZV9tb2R1bGVzL3N2ZWx0ZS1zZWxlY3Qvc3JjL1NlbGVjdGlvbi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgLnNlbGVjdGlvbiB7XG4gICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XG4gICAgb3ZlcmZsb3cteDogaGlkZGVuO1xuICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gIH1cbiJdfQ== */</style>

<div class="selection">
  {@html getSelectionLabel(item)} 
</div>
