<script>
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let getOptionLabel = undefined;
  export let item = undefined;
  export let filterText = '';

  let itemClasses = '';

  $: {
    const classes = [];
    if (isActive) { classes.push('active'); }
    if (isFirst) { classes.push('first'); }
    if (isHover) { classes.push('hover'); }
    if (item.isGroupHeader) { classes.push('groupHeader'); }
    if (item.isGroupItem) { classes.push('groupItem'); }
    itemClasses = classes.join(' ');
  }
</script>

<style>.item {
  cursor: default;
  height: var(--height, 42px);
  line-height: var(--height, 42px);
  padding: var(--itemPadding, 0 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.groupHeader {
  text-transform: var(--groupTitleTextTransform, uppercase);
}

.groupItem {
  padding-left: 40px;
}

.item:active {
  background: var(--itemActiveBackground, #b9daff);
}

.item.active {
  background: var(--itemIsActiveBG, #007aff);
  color: var(--itemIsActiveColor, #fff);
}

.item.first {
  border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
}

.item.hover:not(.active) {
  background: var(--itemHoverBG, #e7f2ff);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9zdmVsdGUtc2VsZWN0L3NyYy9JdGVtLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGVBQWU7RUFDZiwyQkFBMkI7RUFDM0IsZ0NBQWdDO0VBQ2hDLG1DQUFtQztFQUNuQyx1QkFBdUI7RUFDdkIsZ0JBQWdCO0VBQ2hCLG1CQUFtQjtBQUNyQjs7QUFFQTtFQUNFLHlEQUF5RDtBQUMzRDs7QUFFQTtFQUNFLGtCQUFrQjtBQUNwQjs7QUFFQTtFQUNFLGdEQUFnRDtBQUNsRDs7QUFFQTtFQUNFLDBDQUEwQztFQUMxQyxxQ0FBcUM7QUFDdkM7O0FBRUE7RUFDRSx3REFBd0Q7QUFDMUQ7O0FBRUE7RUFDRSx1Q0FBdUM7QUFDekMiLCJmaWxlIjoibm9kZV9tb2R1bGVzL3N2ZWx0ZS1zZWxlY3Qvc3JjL0l0ZW0uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIC5pdGVtIHtcbiAgICBjdXJzb3I6IGRlZmF1bHQ7XG4gICAgaGVpZ2h0OiB2YXIoLS1oZWlnaHQsIDQycHgpO1xuICAgIGxpbmUtaGVpZ2h0OiB2YXIoLS1oZWlnaHQsIDQycHgpO1xuICAgIHBhZGRpbmc6IHZhcigtLWl0ZW1QYWRkaW5nLCAwIDIwcHgpO1xuICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgfVxuXG4gIC5ncm91cEhlYWRlciB7XG4gICAgdGV4dC10cmFuc2Zvcm06IHZhcigtLWdyb3VwVGl0bGVUZXh0VHJhbnNmb3JtLCB1cHBlcmNhc2UpO1xuICB9XG5cbiAgLmdyb3VwSXRlbSB7XG4gICAgcGFkZGluZy1sZWZ0OiA0MHB4O1xuICB9XG5cbiAgLml0ZW06YWN0aXZlIHtcbiAgICBiYWNrZ3JvdW5kOiB2YXIoLS1pdGVtQWN0aXZlQmFja2dyb3VuZCwgI2I5ZGFmZik7XG4gIH1cblxuICAuaXRlbS5hY3RpdmUge1xuICAgIGJhY2tncm91bmQ6IHZhcigtLWl0ZW1Jc0FjdGl2ZUJHLCAjMDA3YWZmKTtcbiAgICBjb2xvcjogdmFyKC0taXRlbUlzQWN0aXZlQ29sb3IsICNmZmYpO1xuICB9XG5cbiAgLml0ZW0uZmlyc3Qge1xuICAgIGJvcmRlci1yYWRpdXM6IHZhcigtLWl0ZW1GaXJzdEJvcmRlclJhZGl1cywgNHB4IDRweCAwIDApO1xuICB9XG5cbiAgLml0ZW0uaG92ZXI6bm90KC5hY3RpdmUpIHtcbiAgICBiYWNrZ3JvdW5kOiB2YXIoLS1pdGVtSG92ZXJCRywgI2U3ZjJmZik7XG4gIH1cbiJdfQ== */</style>



<div class="item {itemClasses}">
  {@html getOptionLabel(item, filterText)}
</div>
